import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

const useFirebaseAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>();
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [idToken, setIdToken] = useState<string | null>();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user && user.emailVerified) {
        const token = await user.getIdToken();
        setCurrentUser(user);
        setIsAuthenticated(true);
        setIdToken(token);
      } else {
        setCurrentUser(null);
        setIsAuthenticated(false);
        setIdToken(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return { isAuthenticated, currentUser, idToken };
};

export default useFirebaseAuthentication;
