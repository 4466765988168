import { useCallback } from "react";
import useFeatures from "./useFeatures";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

type RequestMethod = "GET" | "POST";
type RequestBody = Record<string, unknown> | null;

const useApi = () => {
  const { enabledFeatures } = useFeatures();

  const api = useCallback(
    async (url: string, idToken: string, method: RequestMethod = "GET", body: RequestBody = null)
    : Promise<Response> => {
      const options: RequestInit = {
        method,
        headers: {
          "Authorization": `Bearer ${idToken}`,
          "features": enabledFeatures(),
          "Content-Type": "application/json",
        },
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      return await fetch(baseUrl + url, options);
    },
    [enabledFeatures]
  );

  const jsonApiGet = useCallback(
    async <T>(url: string, idToken: string)
    : Promise<T> => {
      const response = await api(url, idToken);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return await (response.json() as Promise<T>);
    },
    [api]
  );

  const jsonApiPost = useCallback(
    async <T>(url: string, idToken: string, body: RequestBody = null)
    : Promise<T> => {
      const response = await api(url, idToken, "POST", body);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return await (response.json() as Promise<T>);
    },
    [api]
  );

  return { api, jsonApiGet, jsonApiPost };
};

export default useApi;
