import React from "react";
import { Typography, Container } from "@mui/material";

const ThankYouPage: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Thank you page!
      </Typography>
      <Typography variant="body1" align="center">
        <a href="/">Back to main page</a>
      </Typography>
    </Container>
  );
};

export default ThankYouPage;
