import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import useFirebaseAuthentication from "./hooks/useFirebaseAuthentication";

interface ProtectedRouteProps {
  redirect: string;
}

// ! when user is not authenticated and visiting https://tunasuite.com//subscribe?product=product1
// she has to be redirected to https://tunasuite.com/login?redirect=%2Fsubscribe%3Fproduct%3Dproduct1

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({redirect}) => {
  const location = useLocation();
  const { isAuthenticated } = useFirebaseAuthentication();

  if (isAuthenticated === undefined) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to={{pathname: redirect}} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
