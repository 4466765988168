import { Button, Container, CssBaseline, Typography, Box, Link } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

const VerifyEmailPage = () => {
  const navigate = useNavigate();

  const handleResendEmail = () => {
    // Logic to resend confirmation email
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
                        Thank you for signing up!
          </Typography>
          <Typography variant="body1" align="center" sx={{ mt: 2, mb: 4 }}>
                        An email with further instructions has been sent to you.
                        If you have not received an email, you can click&nbsp;
            <Link href="#" onClick={handleResendEmail}>here</Link> to have a new email sent.
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => navigate("/login")}
          >
                        Login
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default VerifyEmailPage;
