import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";

const ALL_FEATURES = ["paidsubscription"];
const DELIMITER = ",";

const parseFeatures = (cookieFeatures: string): Set<string> => {
  return new Set(cookieFeatures.split(DELIMITER).filter((x) => x !== ""));
};

const useFeatures = () => {
  const [features, setFeatures] = useState<Set<string>>(new Set());

  useEffect(() => {
    const cookieFeatures = Cookies.get("features") ?? "";
    setFeatures(parseFeatures(cookieFeatures));
  }, []);

  const isFeatureEnabled = useCallback(
    (feature: string) => {
      return features.has(feature);
    },
    [features]
  );

  const enabledFeatures = useCallback(() => {
    return Array.from(features).join(DELIMITER);
  }, [features]);

  const toggleFeature = useCallback(
    (feature: string, enable: boolean) => {
      const newFeatures = new Set(features);
      if (enable) {
        newFeatures.add(feature);
      } else {
        newFeatures.delete(feature);
      }
      setFeatures(newFeatures);
      Cookies.set("features", Array.from(newFeatures).join(DELIMITER), { path: "/" });
    },
    [features]
  );

  return { isFeatureEnabled, enabledFeatures, toggleFeature, allFeatures: ALL_FEATURES };
};

export default useFeatures;
