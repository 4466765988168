import { AppBar, Toolbar, Typography, Button, Box, Container } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import useFirebaseAuthentication from "../hooks/useFirebaseAuthentication";
import useSubscription from "../hooks/useSubscription";
import useApi from "../hooks/useApi";

const DashboardPage = () => {
  const { currentUser, idToken } = useFirebaseAuthentication();
  const { userProducts } = useSubscription();
  const { jsonApiPost } = useApi();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
      })
      .catch((error) => {
        console.error("DashboardPage: Error signing out: ", error);
      });
  };

  const handleManageSession = async () => {
    if (!idToken) {
      return;
    }
    const response = await jsonApiPost<{ redirectUrl: string }>("/api/subscription/manage-session", idToken);
    window.location.href = response.redirectUrl;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
            {currentUser?.email}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>Log Off</Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h4">Welcome to the Dashboard</Typography>
          {userProducts === undefined && (
            <p>Loading...</p>
          )}

          {userProducts && !userProducts.includes("tuna") && (
            <Button sx={{ margin: 4 }}
              variant="contained" color="primary" href="/subscribe?product=tuna">
              Subscribe Tuna
            </Button>
          )}

          {userProducts && userProducts.includes("tuna") && (
            <Button sx={{ margin: 4 }}
              variant="contained" color="secondary" onClick={handleManageSession}>
              Manage Subscription
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default DashboardPage;
