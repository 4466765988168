import React, { createContext, useContext } from "react";
import { User } from "firebase/auth";
import useFirebaseAuthentication from "./hooks/useFirebaseAuthentication";

interface AuthContextType {
  currentUser: User | null;
}

const AuthContext = createContext<AuthContextType>({ currentUser: null });

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { currentUser } = useFirebaseAuthentication();

  if (currentUser === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
