import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useFirebaseAuthentication from "./hooks/useFirebaseAuthentication";
import useAuthorizationRedirect from "./hooks/useAuthorizationRedirect";

interface AnonymousOnlyRouteProps {
  redirect: string;
}

const AnonymousOnlyRoute: React.FC<AnonymousOnlyRouteProps> = ({ redirect }) => {
  const location = useLocation();
  const { isAuthenticated, idToken } = useFirebaseAuthentication();
  const { redirectToOIDCAuthorization } = useAuthorizationRedirect();
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isAuthenticated === undefined) {
        setLoading(true);
        return;
      }

      if (isAuthenticated) {
        const isRedirecting = await redirectToOIDCAuthorization(idToken!);
        setRedirecting(isRedirecting);
      }

      setLoading(false);
    };

    checkAuthentication();
  }, [isAuthenticated, idToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (redirecting) {
    return <div>Redirecting...</div>;
  }

  if (isAuthenticated) {
    return <Navigate to={{ pathname: redirect }} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default AnonymousOnlyRoute;
