import React from "react";
import { Checkbox, FormControlLabel, Typography, Container, FormGroup } from "@mui/material";
import useFeatures from "../hooks/useFeatures";

const FeaturesPage: React.FC = () => {
  const { isFeatureEnabled, toggleFeature, allFeatures } = useFeatures();

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Enabled features
      </Typography>
      <FormGroup>
        {allFeatures.map((feature) => (
          <FormControlLabel
            key={feature}
            control={
              <Checkbox
                checked={isFeatureEnabled(feature)}
                onChange={(_, checked) => toggleFeature(feature, checked)}
              />
            }
            label={feature}
          />
        ))}
      </FormGroup>
    </Container>
  );
};

export default FeaturesPage;
