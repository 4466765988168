import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBaXSErZwNLxh1Yc4ZWs4IGX4dVnICRAIM",
  authDomain: "tunasuite.com",
  projectId: "tunasuite",
  storageBucket: "tunasuite.appspot.com",
  messagingSenderId: "772471221977",
  appId: "1:772471221977:web:6af38b7f714f89dbff98c8",
  measurementId: "G-SCDRTCWFML",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

(async () => {
  await setPersistence(auth, browserLocalPersistence);
})();
