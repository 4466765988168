/* eslint-disable camelcase */
import { useLocation } from "react-router-dom";
import useApi from "./useApi";

const authorizationEndpoint = "/api/oidc/authorize";

const useAuthorizationRedirect = () => {
  const location = useLocation();
  const { jsonApiGet } = useApi();

  const redirectToOIDCAuthorization = async (idToken: string) => {
    if (location.pathname !== "/login") {
      return false;
    }
    const params = new URLSearchParams(location.search);
    const client_id = params.get("client_id");
    const redirect_uri = params.get("redirect_uri");
    const response_type = params.get("response_type");
    const scope = params.get("scope");
    const state = params.get("state");
    const nonce = params.get("nonce");

    if (client_id && redirect_uri && response_type && scope && state && nonce && idToken) {
      // eslint-disable-next-line max-len
      const requestUrl = `${authorizationEndpoint}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}&nonce=${nonce}`;
      try {
        const response = await jsonApiGet<{redirectUrl: string}>(requestUrl, idToken);

        if (response.redirectUrl) {
          window.location.href = response.redirectUrl;
          return true;
        } else {
          console.error("Authorization failed or no redirection.");
          return false;
        }
      } catch (error) {
        console.error("Error during authorization redirect:", error);
        return false;
      }
    }
    return false;
  };

  return { redirectToOIDCAuthorization };
};

export default useAuthorizationRedirect;
