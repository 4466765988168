import React from "react";
import { signInGoogle, signInEmail } from "../firebase/auth";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAuthorizationRedirect from "../hooks/useAuthorizationRedirect";

const theme = createTheme();

const LoginPage: React.FC = () => {
  const { redirectToOIDCAuthorization } = useAuthorizationRedirect();

  const handleEmailLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const email = (event.currentTarget.email as HTMLInputElement).value;
      const password = (event.currentTarget.password as HTMLInputElement).value;
      const userCredential = await signInEmail(email, password);
      if (userCredential.user.emailVerified) {
        const idToken = await userCredential.user.getIdToken();
        await redirectToOIDCAuthorization(idToken);
      } else {
        alert("Please verify your email before logging in.");
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message === "Email not verified.") {
        window.location.href = "/verify-email";
        return;
      }
      console.error("Error logging in with email and password: ", error);
      alert("Failed to log in with email and password. Please try again.");
    }
  };

  const handleSignInGoogle = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const userCredential = await signInGoogle();
      const idToken = await userCredential.user.getIdToken();
      await redirectToOIDCAuthorization(idToken);
    } catch (error) {
      console.error("Error signing in with Google: ", error);
      alert("Failed to log in with Google. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
                        Sign in
          </Typography>
          <Box component="form" onSubmit={handleEmailLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
                            Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/password-forgot" variant="body2">
                                    Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Button
              onClick={handleSignInGoogle}
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              sx={{ mt: 2 }}
            >
                            Sign in with Google
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
